body {
  margin: 0;
}

.global-nav {
  color: #f7f7f7;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5rem;
  z-index: 100;
}

.global-nav h1 {
  max-width: 40em;
  font-style: normal;
  font-weight: 100;
  margin-top: 0;
}

@media (max-width: 772px) {
  .global-nav h1 {
    font-size: 2.2281872349rem;
    line-height: 3rem;
    margin-bottom: 0.835rem;
    padding-top: 0.166rem;
  }
}

@media (min-width: 772px) {
  .global-nav h1 {
    font-size: 2.910285368rem;
    line-height: 3.5rem;
    margin-bottom: 0.8rem;
    padding-top: 0.201rem;
  }
}

@media (min-width: 1681px) {
  .global-nav h1 {
    margin-bottom: 0.85rem;
    padding-top: 0.151rem;
  }
}

.global-nav h2 {
  max-width: 40em;
  font-style: normal;
  font-weight: 100;
  margin-top: 0;
}

@media (max-width: 772px) {
  .global-nav h2 {
    font-size: 1.83274rem;
    line-height: 2.5rem;
    margin-bottom: 0.9rem;
    padding-top: 0.101rem;
  }
}

@media (min-width: 772px) {
  .global-nav h2 {
    font-size: 2.2281872349rem;
    line-height: 3rem;
    margin-bottom: 0.8rem;
    padding-top: 0.201rem;
  }
}

.global-nav h3 {
  max-width: 40em;
  font-style: normal;
  font-weight: 300;
  margin-top: 0;
}

@media (max-width: 772px) {
  .global-nav h3 {
    font-size: 1.4927113703rem;
    line-height: 2rem;
    margin-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
}

@media (min-width: 772px) {
  .global-nav h3 {
    font-size: 1.7059558517rem;
    line-height: 2.5rem;
    margin-bottom: 0.9rem;
    padding-top: 0.101rem;
  }
}

.global-nav .global-nav__matrix-title,
.global-nav h4 {
  max-width: 40em;
  font-style: normal;
  font-weight: 300;
  margin-top: 0;
}

@media (max-width: 772px) {

  .global-nav .global-nav__matrix-title,
  .global-nav h4 {
    font-size: 1.22176rem;
    line-height: 1.5rem;
    margin-bottom: 0.7rem;
    padding-top: 0.301rem;
  }
}

@media (min-width: 772px) {

  .global-nav .global-nav__matrix-title,
  .global-nav h4 {
    font-size: 1.306122449rem;
    line-height: 2rem;
    margin-bottom: 0.95rem;
    padding-top: 0.051rem;
  }
}

@media (min-width: 1681px) {

  .global-nav .global-nav__matrix-title,
  .global-nav h4 {
    margin-bottom: 1rem;
    padding-top: 0.001rem;
  }
}

.global-nav .global-nav__flex-container .global-nav__matrix-title,
.global-nav h5 {
  max-width: 40em;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  margin-bottom: 1.1rem;
  margin-top: 0;
  padding-top: 0.401rem;
}

.global-nav h6 {
  max-width: 40em;
  font-size: 1rem;
  font-style: italic;
  font-weight: 300;
  line-height: 1.5rem;
  margin-bottom: 1.1rem;
  margin-top: 0;
  padding-top: 0.338rem;
}

@media (min-width: 1681px) {
  .global-nav h6 {
    padding-top: 0.345rem;
  }
}

.global-nav .p-text--default,
.global-nav cite,
.global-nav p {
  line-height: 1.5rem;
  margin-top: 0;
  padding-top: 0.4005rem;
}

.global-nav .p-text--default,
.global-nav cite {
  margin-bottom: 0.1rem;
}

.global-nav p {
  margin-bottom: 1.1rem;
}

p:not([class*='p-heading--']):not([class*='p-muted-heading'])+.global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+.global-nav p {
  margin-top: -0.5rem;
}

.global-nav .global-nav__muted-heading,
.global-nav .p-text--small,
.global-nav small {
  font-size: 0.875rem;
  line-height: 1rem;
  margin-bottom: 0.8rem;
  padding-top: 0.2005rem;
}

@media (min-width: 1681px) {

  .global-nav .global-nav__muted-heading,
  .global-nav .p-text--small,
  .global-nav small {
    padding-top: 0.2006rem;
  }
}

.global-nav .p-text--x-small {
  font-size: 0.765625rem;
  line-height: 1rem;
  margin-bottom: 0.8rem;
  padding-top: 0.2505rem;
}

@media (min-width: 1681px) {
  .global-nav .p-text--x-small {
    padding-top: 0.2006rem;
  }
}

.global-nav .global-nav__muted-heading {
  color: #666;
  margin-bottom: 0.8rem;
  margin-top: 0;
  padding-top: 0.2rem;
  text-transform: uppercase;
}

.global-nav strong {
  font-weight: 400;
}

.global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+.p-heading--1,
.global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+.p-heading--one,
.global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+h1 {
  padding-top: 1.7rem;
}

@media (max-width: 772px) {

  .global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+.p-heading--1,
  .global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+.p-heading--one,
  .global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+h1 {
    padding-top: 1.665rem;
  }
}

.global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+.p-heading--2,
.global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+.p-heading--two,
.global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+h2 {
  padding-top: 1.7rem;
}

@media (max-width: 772px) {

  .global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+.p-heading--2,
  .global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+.p-heading--two,
  .global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+h2 {
    padding-top: 1.6rem;
  }
}

@media (max-width: 772px) {

  .global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+.p-heading--3,
  .global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+.p-heading--three,
  .global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+h3 {
    padding-top: 2rem;
  }
}

@media (min-width: 772px) {

  .global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+.p-heading--3,
  .global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+.p-heading--three,
  .global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+h3 {
    padding-top: 1.6rem;
  }
}

@media (max-width: 772px) {

  .global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+.p-heading--4,
  .global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+.p-heading--four,
  .global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+h4 {
    padding-top: 1.8rem;
  }
}

@media (min-width: 772px) {

  .global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+.p-heading--4,
  .global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+.p-heading--four,
  .global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+h4 {
    padding-top: 1.55rem;
  }
}

.global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+.p-heading--5,
.global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+.p-heading--6,
.global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+.p-heading--five,
.global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+.p-heading--six,
.global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+h5,
.global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+h6 {
  padding-top: 1.9rem;
}

.global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading'])+.p-muted-heading {
  padding-top: 1.7rem;
}

.global-nav .u-hide-text {
  overflow: hidden;
  text-indent: calc(100% + 10rem);
  white-space: nowrap;
}

.global-nav .u-no-margin--bottom:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(p):not(small):not([class*='p-heading']) {
  margin-bottom: 0 !important;
}

@media (max-width: 772px) {
  .global-nav h1.u-no-margin--bottom {
    margin-bottom: -0.165rem !important;
  }
}

@media (min-width: 772px) {
  .global-nav h1.u-no-margin--bottom {
    margin-bottom: -0.2rem !important;
  }
}

@media (min-width: 1681px) {
  .global-nav h1.u-no-margin--bottom {
    margin-bottom: -0.15rem !important;
  }
}

@media (max-width: 772px) {
  .global-nav h2.u-no-margin--bottom {
    margin-bottom: -0.1rem !important;
  }
}

@media (min-width: 772px) {
  .global-nav h2.u-no-margin--bottom {
    margin-bottom: -0.2rem !important;
  }
}

@media (max-width: 772px) {
  .global-nav h3.u-no-margin--bottom {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 772px) {
  .global-nav h3.u-no-margin--bottom {
    margin-bottom: -0.1rem !important;
  }
}

@media (max-width: 772px) {

  .global-nav .u-no-margin--bottom.global-nav__matrix-title,
  .global-nav h4.u-no-margin--bottom {
    margin-bottom: 0.2rem !important;
  }
}

@media (min-width: 772px) {

  .global-nav .u-no-margin--bottom.global-nav__matrix-title,
  .global-nav h4.u-no-margin--bottom {
    margin-bottom: -0.05rem !important;
  }
}

@media (min-width: 1681px) {

  .global-nav .u-no-margin--bottom.global-nav__matrix-title,
  .global-nav h4.u-no-margin--bottom {
    margin-bottom: 0 !important;
  }
}

.global-nav .global-nav__flex-container .u-no-margin--bottom.global-nav__matrix-title,
.global-nav h5.u-no-margin--bottom,
.global-nav h6.u-no-margin--bottom,
.global-nav p.u-no-margin--bottom {
  margin-bottom: 0.1rem !important;
}

.global-nav .u-no-margin--bottom.global-nav__muted-heading,
.global-nav .u-no-margin--bottom.p-text--small,
.global-nav .u-no-margin--bottom.p-text--x-small,
.global-nav small.u-no-margin--bottom {
  margin-bottom: -0.2rem !important;
}

.global-nav .measure--p {
  max-width: 40em;
}

.global-nav html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #111;
  font-family: Ubuntu, -apple-system, Segoe UI, Roboto, Oxygen, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-smoothing: subpixel-antialiased;
  font-weight: 300;
  line-height: 1.5rem;
}

@media screen and (max-width: 1681px) {
  .global-nav html {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1681px) {
  .global-nav html {
    font-size: 1.125rem;
    line-height: 1.6875rem;
  }
}

.global-nav p {
  max-width: 40em;
}

.global-nav .p-text--small.dense,
.global-nav small.dense {
  margin-bottom: 1.3rem;
}

.global-nav p:not([class*='p-heading--']):not([class*='p-muted-heading']):empty {
  line-height: 0;
  margin: 0;
  padding: 0;
}

.global-nav sub,
.global-nav sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.global-nav abbr[title] {
  border-bottom: 0.1em dotted;
  cursor: help;
  text-decoration: none;
}

.global-nav .u-hide {
  display: none !important;
}

@media screen and (max-width: 772px) {
  .global-nav .u-hide--small {
    display: none !important;
  }
}

@media (min-width: 772px) and (max-width: 1036px) {
  .global-nav .u-hide--medium {
    display: none !important;
  }
}

@media screen and (min-width: 1036px) {
  .global-nav .u-hide--large {
    display: none !important;
  }
}

.global-nav .u-no-margin {
  margin: 0 !important;
}

.global-nav .u-no-margin--top {
  margin-top: 0 !important;
}

.global-nav .u-no-margin--right {
  margin-right: 0 !important;
}

.global-nav .u-no-margin--left {
  margin-left: 0 !important;
}

.global-nav .u-table-cell-padding-overlap {
  margin-bottom: -0.5rem !important;
  margin-top: calc(-0.5rem - 1px) !important;
}

.global-nav .u-no-padding {
  padding: 0 !important;
}

.global-nav .u-no-padding--top {
  padding-top: 0 !important;
}

.global-nav .u-no-padding--right {
  padding-right: 0 !important;
}

.global-nav .u-no-padding--bottom {
  padding-bottom: 0 !important;
}

.global-nav .u-no-padding--left {
  padding-left: 0 !important;
}

.global-nav * {
  box-sizing: border-box;
}

.global-nav .global-nav__header {
  width: 100%;
  z-index: 99;
}

.global-nav .global-nav__header-row {
  display: -webkit-box;
  display: flex;
  width: 100%;
}

.global-nav .global-nav__header-logo {
  margin: 0;
}

.global-nav .global-nav__header-logo-anchor {
  display: inline-block;
  line-height: 0;
  padding: 0.6875rem 1rem 0.6875rem 0;
}

@media (max-width: 875px) {
  .global-nav .global-nav__header-logo-anchor {
    display: block;
    padding-left: 0;
    padding-right: 1.5rem;
  }
}

.global-nav .global-nav__header-list {
  list-style: none;
  margin: 0 0 0 auto;
  padding: 0;
}

.global-nav .global-nav__header-link {
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative;
}

.global-nav .global-nav__header-link.is-selected>.global-nav__header-link-anchor:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.global-nav .global-nav__header-link-anchor {
  color: #f7f7f7;
  display: inline-block;
  line-height: 1.25rem;
  margin-bottom: 0;
  padding: 0.375rem 0.5rem;
  text-decoration: none;
}

@media (max-width: 875px) {
  .global-nav .global-nav__header-link-anchor {
    padding-left: 0;
  }
}

.global-nav .has-dropdown .global-nav__header-link-anchor {
  padding: 0.375rem 1.625rem 0.375rem 0.5rem;
}

.global-nav .has-dropdown .global-nav__header-link-anchor:after {
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23f7f7f7' d='M1.86 5l6.157 3.84L14.139 5 15 6.357l-6.983 5.03L1 6.358z' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-size: 100%;
  content: '';
  height: 0.5rem;
  right: 0.5rem;
  top: 0.75rem;
  width: 0.625rem;
}

.global-nav .global-nav__dropdown,
.global-nav .has-dropdown .global-nav__header-link-anchor:after {
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  position: absolute;
}

.global-nav .global-nav__dropdown {
  -webkit-transition-duration: 0.333s;
  transition-duration: 0.333s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  background-color: #262626;
  margin: 0;
  overflow: hidden;
  top: 2rem;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  width: 100%;
  z-index: 98;
}

.global-nav .global-nav__dropdown.show-content {
  height: auto;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.global-nav .global-nav__dropdown-content {
  margin: 0 auto;
  padding-top: 0;
}

@media (max-width: 875px) {
  .global-nav .global-nav__dropdown-content {
    padding: 0.5rem 0;
  }
}

.global-nav .global-nav__row {
  margin: 0 auto;
  padding: 0 1.5rem;
}

@media (max-width: 875px) {
  .global-nav .global-nav__row {
    padding: 0 1rem;
  }
}

.global-nav .global-nav__row.is-bordered:after {
  background-color: #666;
  content: '';
  display: block;
  height: 1px;
  margin-top: 2rem;
  width: 100%;
}

.global-nav .global-nav__strip {
  margin: 0;
  padding: 2rem 0 0;
}

@media (max-width: 875px) {
  .global-nav .global-nav__strip {
    padding-top: 1rem;
  }
}

.global-nav .global-nav__mobile-strip {
  display: none;
}

@media (max-width: 875px) {
  .global-nav .global-nav__mobile-strip {
    display: inherit;
  }
}

.global-nav .global-nav__mobile-strip .global-nav__split-list {
  display: none;
}

.global-nav .global-nav__flex-container {
  display: -webkit-box;
  display: flex;
}

.global-nav .global-nav__flex-container .global-nav__matrix-item {
  border: 0;
  padding: 0 0 2rem;
}

.global-nav .global-nav__flex-container .global-nav__matrix-title {
  font-weight: 400;
}

.global-nav .global-nav__others-col {
  margin-top: 0;
  width: 74.17808%;
}

.global-nav .global-nav__resources-col {
  margin-left: 3.2877%;
  margin-top: 0;
  width: 22.53423%;
}

.global-nav .global-nav__expanding-row {
  border-top: 1px solid #666;
  cursor: pointer;
  font-size: 0.8175rem;
  line-height: 1.5;
  margin: 0;
  max-width: inherit;
  padding: 1rem 0;
  position: relative;
}

.global-nav .global-nav__expanding-row:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23f7f7f7' d='M1.86 5l6.157 3.84L14.139 5 15 6.357l-6.983 5.03L1 6.358z' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 0.8175rem;
  content: '';
  height: 0.8rem;
  position: absolute;
  right: 0.5rem;
  top: 1.2rem;
  width: 0.8175rem;
}

.global-nav .global-nav__expanding-row.is-active:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.global-nav .global-nav__expanding-row.is-active+.global-nav__split-list {
  display: block;
}

.global-nav .global-nav__matrix {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (max-width: 875px) {
  .global-nav .global-nav__matrix {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
}

.global-nav .global-nav__matrix-item {
  border-top: 1px solid #666;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  flex-wrap: wrap;
  margin: 0;
  padding: 1rem;
  width: 33.33%;
}

.global-nav .global-nav__matrix-item:nth-child(3n + 1) {
  padding-left: 0;
}

.global-nav .global-nav__matrix-item:nth-child(3n + 3) {
  border-right: 0;
  padding-right: 0;
}

@media (min-width: 875px) {

  .global-nav .global-nav__matrix-item:first-child,
  .global-nav .global-nav__matrix-item:nth-child(2),
  .global-nav .global-nav__matrix-item:nth-child(3) {
    border-top: 0;
  }
}

@media (max-width: 875px) {
  .global-nav .global-nav__matrix-item {
    padding: 1rem 0;
    width: 100%;
  }
}

.global-nav .global-nav__matrix-image {
  display: inline-block;
  height: auto;
  margin-bottom: 1rem;
  margin-right: 0.75rem;
  max-height: 2rem;
  max-width: 2rem;
  position: relative;
  top: 0.1rem;
}

@media (max-width: 875px) {
  .global-nav .global-nav__matrix-image {
    display: none;
  }
}

.global-nav .global-nav__matrix-content {
  display: -webkit-box;
  display: flex;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin: 0;
  padding-right: 1rem;
  width: 100%;
}

.global-nav .global-nav__matrix-title {
  line-height: 2rem;
}

@media (max-width: 875px) {
  .global-nav .global-nav__matrix-title {
    margin-bottom: 0;
  }
}

.global-nav .global-nav__matrix-desc {
  margin: -1rem 0 0.1rem 2.75rem;
}

@media (max-width: 875px) {
  .global-nav .global-nav__matrix-desc {
    display: none;
  }
}

.global-nav .global-nav__link {
  color: #f7f7f7;
  display: -webkit-inline-box;
  display: inline-flex;
  text-decoration: none;
}

.global-nav .global-nav__link:hover {
  text-decoration: underline;
}

.global-nav .global-nav__split-list {
  -webkit-column-gap: 2rem;
  -moz-column-gap: 2rem;
  column-gap: 2rem;
  -webkit-columns: 2;
  -moz-columns: 2;
  column-count: 2;
  list-style: none;
  margin: 0 0 1.5rem;
  padding: 0;
}

.global-nav .global-nav__split-list .global-nav__list-item {
  display: inline-block;
  margin: 0;
  padding: 0.25rem 0;
  width: 100%;
}

.global-nav .global-nav__inline-list {
  margin: 0.5rem 0 0;
  padding: 0 0 1rem 2.75rem;
}

@media (max-width: 875px) {
  .global-nav .global-nav__inline-list {
    margin-top: 0;
    padding: 0;
  }
}

.global-nav .global-nav__inline-list .global-nav__list-item {
  display: inline;
  font-weight: 400;
  list-style: none;
  margin-right: 1.25rem;
}

.global-nav .global-nav__muted-heading {
  color: #f7f7f7;
  font-weight: 400;
}

@media (max-width: 875px) {
  .global-nav .global-nav__muted-heading {
    font-size: 0.8175rem;
  }
}

.global-nav .global-nav__overlay {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  background-color: rgba(17, 17, 17, 0.4);
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  width: 100%;
  z-index: 97;
}

@media (max-width: 875px) {
  .global-nav .global-nav__overlay {
    display: none;
  }
}

.global-nav .global-nav__overlay.show-overlay {
  opacity: 1;
  pointer-events: all;
}

@media (max-width: 875px) {
  body {
    position: relative;
  }

  .u-hide--mobile {
    display: none;
  }
}

.skip-content a {
  color: #007aa6;
  display: block;
  left: -999px;
  position: absolute;
  top: -999px;
}

.skip-content a:focus {
  background: #fff;
  border: 1px solid #007aa6;
  left: 0;
  padding: 3px;
  position: relative;
  top: 0;
  z-index: 999999;
}

.has-m-tb-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* overwrite color */
.is-palette-1 {
  background-color: #14274E !important;
}

.is-palette-2 {
  background-color: #394867 !important;
}

.is-palette-3 {
  background-color: #F1F6F9 !important;
}

.is-palette-4 {
  background-color: #9ba4b478 !important;
}

.has-white-text {
  color: #f5ebeb !important;
}

.has-black-text {
  color: black !important;
}

.has-light-background {
  background-color: #f5ebeb !important;
}

/* do not group these rules */
.is-palette-2::-webkit-input-placeholder {
  color: #f5ebeb;
}

.is-palette-2:-moz-placeholder {
  /* FF 4-18 */
  color: #f5ebeb;
  opacity: 1;
}

.is-palette-2::-moz-placeholder {
  /* FF 19+ */
  color: #f5ebeb;
  opacity: 1;
}

.is-palette-2:-ms-input-placeholder {
  /* IE 10+ */
  color: #f5ebeb;
}

.is-palette-2::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #f5ebeb;
}

.is-palette-2::placeholder {
  /* modern browser */
  color: #f5ebeb;
}

.is-placeholder-black:hover::-webkit-input-placeholder {
  color: black !important;
}

.is-placeholder-black:hover:-moz-placeholder {
  /* FF 4-18 */
  color: black !important;
}

.is-placeholder-black:hover::-moz-placeholder {
  /* FF 19+ */
  color: black !important;
}

.is-placeholder-black:hover:-ms-input-placeholder {
  /* IE 10+ */
  color: black !important;
}

.is-placeholder-black:hover::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black !important;
}

.is-placeholder-black:hover::placeholder {
  /* modern browser */
  color: black !important;
}

.is-placeholder-black:focus {
  color: black !important;
}

.is-placeholder-black:focus {
  /* FF 4-18 */
  color: black !important;
}

.is-placeholder-black:focus {
  /* FF 19+ */
  color: black !important;
}

.is-placeholder-black:focus {
  /* IE 10+ */
  color: black !important;
}

.is-placeholder-black:focus {
  /* Microsoft Edge */
  color: black !important;
}

.is-placeholder-black:focus {
  /* modern browser */
  color: black !important;
}

.is-placeholder-black:hover {
  color: black !important;
}

.is-placeholder-black:hover {
  /* FF 4-18 */
  color: black !important;
}

.is-placeholder-black:hover {
  /* FF 19+ */
  color: black !important;
}

.is-placeholder-black:hover {
  /* IE 10+ */
  color: black !important;
}

.is-placeholder-black:hover {
  /* Microsoft Edge */
  color: black !important;
}

.is-placeholder-black:hover {
  /* modern browser */
  color: black !important;
}
